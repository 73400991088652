function getAllUsers(start, limit) {
  return window.$http({
    url: '/api/v1/admin/get-all-users',
    method: 'get',
    params: {
      start,
      limit,
    },
  });
}

export default getAllUsers;
