function getAllCompanies(start = 0) {
  return window.$http({
    url: '/api/v1/admin/companies',
    method: 'get',
    params: {
      start,
    },
  });
}

export default getAllCompanies;
