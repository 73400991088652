function createReportSheet(startDate, endDate, remarks, transactionId) {
  const data = {
    start_date: startDate,
    end_date: endDate,
    remarks_text: remarks,
    transaction_id: transactionId,
  };

  return window.$http({
    url: '/api/v1/customer/report-sheet',
    method: 'post',
    headers: { Accept: 'application/pdf' },
    params: {
      download: true,
    },
    data,
  });
}

export default createReportSheet;
