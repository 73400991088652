const statuses = [
  {
    text: 'Administrator',
    value: 'ROLE_ADMIN',
  },
  {
    text: 'Customer',
    value: 'ROLE_CUSTOMER',
  },
  {
    text: 'User',
    value: 'ROLE_USER',
  },
];

export default statuses;
