<template>
  <v-dialog v-model="dialog" max-width="500" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn class="btn btn-radius-corner" @click.native="agree">
          {{ agreeBtnText }}
        </v-btn>
        <v-btn v-if="!(isOverlappingMessage || isInvalidLabelMessage || duplicateClassName
          || emptyClassName)"
          class="btn btn-gray btn-radius-corner"
          @click.native="cancel">
          {{ cancelBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    agreeBtnText: null,
    cancelBtnText: null,
  }),
  methods: {
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      if (message?.includes('cancel the ongoing training') || message?.includes('delete this training')) {
        this.agreeBtnText = 'Yes';
        this.cancelBtnText = 'No';
      } else if (message?.includes('You have unsaved changes')) {
        this.agreeBtnText = 'Yes';
        this.cancelBtnText = 'Cancel';
      } else if (message?.includes('you have overlapped labels')
        || message?.includes('labels outside of the canvas')
        || message?.includes('Only unique names are allowed')
        || message?.includes('Please enter a class name')) {
        this.agreeBtnText = 'Ok';
      } else {
        this.agreeBtnText = 'Ok';
        this.cancelBtnText = 'Cancel';
      }
      return new Promise((resolve, reject) => { this.resolve = resolve; this.reject = reject; });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
  computed: {
    isOverlappingMessage: {
      get() { return this.title === 'Overlapped Labels'; },
    },
    isInvalidLabelMessage: {
      get() { return this.title === 'Invalid Labels'; },
    },
    duplicateClassName: {
      get() { return this.title === 'Duplicate Name'; },
    },
    emptyClassName: {
      get() { return this.title === 'No Class Name'; },
    },
  },
};
</script>
