const statuses = [
  {
    text: 'Active',
    value: 1,
  },
  {
    text: 'Not active',
    value: 0,
  },
];

export default statuses;
