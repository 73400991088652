import resetPw from '@/api/auth/resetPw';

export default {
  methods: {
    onResetPw(item) {
      this.$refs.confirm.open(this.$t('notification.userpage.reset-password'),
        this.$t('notification.userpage.do-you-want-to-reset-the-password',
          { name: `${item.user.firstname} ${item.user.lastname}`, email: item.user.email }))
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show',
              this.$t('notification.userpage.the-password-is-beeing-reset'));
            resetPw(item.user)
              .then(() => {
                this.$store.dispatch('notificationbar/showNotification', { msg: this.$t('notification.userpage.the-password-has-been-reset'), color: 'info', show: true });
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'));
          }
        });
    },
  },
};
