function getTrainingStatistics(companyId) {
  const url = companyId
    ? `/api/v1/admin/dashboard/general-training-statistic?company_id=${companyId}`
    : '/api/v1/admin/dashboard/general-training-statistic';

  return window.$http({
    url,
    method: 'get',
  });
}

export default getTrainingStatistics;
